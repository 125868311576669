import _ from "lodash"

export const formatMenuQuery = (wpMenu, menuOrder) => {
  let ordered = menuOrder.allWpMenu.edges[0].node.menuItems.nodes
  let colA = []
  let colB = []
  let colC = []

  wpMenu.menuItems.nodes.forEach(node => {
    if (node.connectedNode?.node.slug !== "homepage") {
      if (
        node.connectedNode?.node.wpParent === null &&
        node.connectedNode.node.wpChildren.nodes.length === 0
      ) {
        let ind = _.findKey(ordered, function (o) {
          return o.connectedNode?.node.uri === node.connectedNode.node.uri
        })
        colA.push({
          node: node.connectedNode?.node,
          hasChildren: false,
          children: null,
          order: ordered[ind]?.order,
        })
      } else if (
        node.connectedNode?.node.wpParent === null &&
        node.connectedNode.node.wpChildren.nodes.length > 0
      ) {
        let colA_children = []
        node.connectedNode.node.wpChildren.nodes.forEach(nodeOfnode => {
          let isInMenu = _.findKey(ordered, function (o) {
            return o.connectedNode?.node.uri === nodeOfnode.uri
          })

          if (
            nodeOfnode.wpChildren.nodes.length > 0 &&
            isInMenu !== undefined
          ) {
            let tChildren = []
            nodeOfnode.wpChildren.nodes.forEach(childrenOfChild => {
              let ind = _.findKey(ordered, function (o) {
                return o.connectedNode?.node.uri === childrenOfChild.uri
              })

              if (ind !== undefined) {
                tChildren.push({
                  node: childrenOfChild,
                  hasChildren: false,
                  children: tChildren,
                  order: ordered[ind]?.order,
                })
              }
            })

            let ind = _.findKey(ordered, function (o) {
              return o.connectedNode?.node.uri === nodeOfnode.uri
            })

            colA_children.push({
              node: nodeOfnode,
              hasChildren: true,
              children: _.sortBy(tChildren, [
                function (o) {
                  return o.order
                },
              ]),
              order: ordered[ind]?.order,
            })

            // console.log(ordered[ind]?.order)
          } else {
            let ind = _.findKey(ordered, function (o) {
              return o.connectedNode?.node.uri === nodeOfnode.uri
            })
            if (isInMenu !== undefined) {
              colA_children.push({
                node: nodeOfnode,
                hasChildren: false,
                children: null,
                order: ordered[ind]?.order,
              })
            }
          }
        })
        let ind = _.findKey(ordered, function (o) {
          return o.connectedNode?.node.uri === node.connectedNode.node.uri
        })
        colA.push({
          node: node.connectedNode?.node,
          hasChildren: true,
          children: _.sortBy(colA_children, [
            function (o) {
              return o.order
            },
          ]),
          order: ordered[ind]?.order,
        })

        node.connectedNode.node.wpChildren.nodes.forEach(innerNode => {
          if (innerNode.wpChildren.nodes.length === 0) {
            let ind = _.findKey(ordered, function (o) {
              return o.connectedNode?.node.uri === innerNode.uri
            })

            colB.push({
              node: innerNode,
              hasChildren: false,
              children: null,
              order: ordered[ind]?.order,
            })
          } else {
            // console.log(innerNode.wpChildren.nodes)
            let tChildren = []
            innerNode.wpChildren.nodes.forEach(tNode => {
              let ind = _.findKey(ordered, function (o) {
                return o.connectedNode?.node.uri === tNode.uri
              })
              let isInMenu = _.findKey(ordered, function (o) {
                return o.connectedNode?.node.uri === tNode.uri
              })
              if (isInMenu !== undefined) {
                tChildren.push({
                  node: tNode,
                  hasChildren: false,
                  children: null,
                  order: ordered[ind]?.order,
                })
              }
            })

            colB.push({
              node: innerNode,
              hasChildren: tChildren.length > 0 ? true : false,
              children:
                tChildren.length > 0
                  ? _.sortBy(tChildren, [
                      function (o) {
                        return o.order
                      },
                    ])
                  : null,
              order: 0,
            })

            innerNode.wpChildren.nodes.forEach(innerInnerNode => {
              let isInMenu = _.findKey(ordered, function (o) {
                return o.connectedNode?.node.uri === innerInnerNode.uri
              })

              if (isInMenu !== undefined) {
                colC.push({ node: innerInnerNode, hasChildren: false })
              }
            })
          }
        })
      }
    }
  })

  // console.log("A", colA, colB, colC)
  return { colA, colB, colC }
}
