import { useStaticQuery, graphql } from "gatsby"

export const useContatti = () => {
  const data = useStaticQuery(graphql`
    query contattiQuery {
      wpPage(slug: { eq: "contatti" }) {
        id
        contattiFondazione {
          informazioni
        }
        translated {
          contattiFondazione {
            informazioni
          }
        }
      }
    }
  `)
  return data
}
