import { useStaticQuery, graphql } from "gatsby"

export const useFooterSitemap = () => {
  const data = useStaticQuery(graphql`
    query FooterSitemapQuery {
      allWpPage(
        filter: {
          slug: {
            in: [
              "contatti"
              "mostre-e-altri-eventi"
              "pubblicazioni"
              "chi-siamo"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            title
            uri
            translated {
              title
              uri
            }
          }
        }
      }
    }
  `)
  return data
}
