import { useStaticQuery, graphql } from "gatsby"

export const useWpMenuOrder = () => {
  const data = useStaticQuery(graphql`
    query MenuOrderQuery {
      allWpMenu(filter: { slug: { eq: "reviewed" } }) {
        edges {
          node {
            menuItems {
              nodes {
                order
                connectedNode {
                  node {
                    uri
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data
}
