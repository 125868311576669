import { useStaticQuery, graphql } from "gatsby"

export const useSiteTranslatedRoutes = () => {
  const data = useStaticQuery(graphql`
    query wpCompleteMenuQuery {
      posts: allWpPost(filter: { locale: { locale: { eq: "it_IT" } } }) {
        edges {
          node {
            id
            uri
            title
            translated {
              id
              title
              localizedWpmlUrl
            }
          }
        }
      }
      menu: wpMenu {
        menuItems {
          nodes {
            uri
            connectedNode {
              node {
                ... on WpPage {
                  id
                  uri
                  title
                  slug
                  translated {
                    title
                    id
                    localizedWpmlUrl
                  }
                  wpParent {
                    node {
                      id
                      slug
                      ... on WpPage {
                        id
                        uri
                        title
                        translated {
                          title
                          id
                          localizedWpmlUrl
                        }
                      }
                    }
                  }
                  wpChildren {
                    nodes {
                      id
                      slug
                      ... on WpPage {
                        id
                        uri
                        title
                        translated {
                          id
                          title
                          localizedWpmlUrl
                        }
                        wpChildren {
                          nodes {
                            id
                            slug
                            ... on WpPage {
                              id
                              uri
                              title
                              translated {
                                title
                                id
                                localizedWpmlUrl
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let translationRoutes = []

  data.menu.menuItems.nodes.forEach(node => {
    if (node.uri === "/homepage/") {
      let temp = {
        default: {
          title: "homepage",
          uri: "/",
        },
        translated: {
          title: "homepage",
          uri: "/en/",
        },
      }
      translationRoutes.push(temp)
    } else {
      if (
        node.connectedNode?.node.wpParent === null &&
        node.connectedNode.node.wpChildren.nodes.length === 0
      ) {
        let temp = {}
        temp.default = {
          title: node.connectedNode.node.title,
          uri: node.connectedNode.node.uri,
        }
        if (node.connectedNode.node.translated.length !== 0) {
          temp.translated = {
            title: node.connectedNode.node.translated[0].title,
            uri: node.connectedNode.node.translated[0].localizedWpmlUrl,
          }
        }
        translationRoutes.push(temp)
      } else if (
        node.connectedNode?.node.wpParent === null &&
        node.connectedNode.node.wpChildren.nodes.length > 0
      ) {
        node.connectedNode.node.wpChildren.nodes.forEach(innerNode => {
          if (innerNode.wpChildren.nodes.length === 0) {
            let temp = {}
            temp.default = {
              title: innerNode.title,
              uri: innerNode.uri,
            }
            if (innerNode.translated.length !== 0) {
              temp.translated = {
                title: innerNode.translated[0].title,
                uri: innerNode.translated[0].localizedWpmlUrl,
              }
            }
            translationRoutes.push(temp)
          } else {
            innerNode.wpChildren.nodes.forEach(innerInnerNode => {
              let temp = {}
              temp.default = {
                title: innerInnerNode.title,
                uri: innerInnerNode.uri,
              }
              if (innerInnerNode.translated.length !== 0) {
                temp.translated = {
                  title: innerInnerNode.translated[0].title,
                  uri: innerInnerNode.translated[0].localizedWpmlUrl,
                }
              }
              translationRoutes.push(temp)
            })
          }
        })
      }
    }
  })

  data.posts.edges.forEach(edge => {
    let temp = {}
    temp.default = {
      title: edge.node.title,
      uri: edge.node.uri,
    }
    if (edge.node.translated.length > 0) {
      temp.translated = {
        title: edge.node.translated[0].title,
        uri: edge.node.translated[0].localizedWpmlUrl,
      }
    }
    translationRoutes.push(temp)
  })

  translationRoutes.push({
    default: {
      title: "Search",
      uri: "/search/",
    },
    translated: {
      title: "Search",
      uri: "/en/search/",
    },
  })

  return translationRoutes
}
