import React, { useEffect, useState } from "react"
import { StateContext } from "./StateContext"
import { useSiteTranslatedRoutes } from "../../hooks/wp/useSiteTranslatedRoutes"
import _ from "lodash"
import { Link } from "gatsby"

const LocalizeLink = ({ to, showLabel, children, params }) => {
  const [label, setLabel] = useState(null)
  const [localizedUrl, setLocalizedUrl] = useState(to)
  const routes = useSiteTranslatedRoutes()

  useEffect(() => {
    let dest = _.find(routes, function (n) {
      if (n.default?.uri === to) {
        return n
      }
    })

    if (StateContext.locale === "it") {
      setLocalizedUrl(dest ? dest.default?.uri : "/pagina-non-trovata/")
      showLabel && setLabel(dest.default?.title)
    } else {
      setLocalizedUrl(dest ? dest.translated?.uri : "/page-not-found/")
      showLabel && setLabel(dest.translated?.title)
    }
  }, [routes, to, showLabel])

  return (
    <>
      <Link to={params !== undefined ? localizedUrl + params : localizedUrl}>
        {showLabel ? label : children}
      </Link>
    </>
  )
}
export default LocalizeLink
