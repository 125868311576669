import React, { useState, useEffect, useRef } from "react"
import { AnimatePresence, motion, useScroll } from "framer-motion"

import Header from "./Header"
import AnimatedMain from "./AnimatedMain"
import Footer from "./Footer"

import { StateContext } from "./StateContext"
import useWindowSize from "../../hooks/useWindowSize"

const Layout = ({ location, children }) => {
  const [isSpecialPage, setIsSpecialPage] = useState(
    location.pathname.includes("ambiti") || location.pathname.includes("scopes")
      ? true
      : false
  )
  const [menuState, setMenuState] = useState(false)

  const [currentLocale, setCurrentLocale] = useState(
    location.pathname.includes("/en/") ? "en" : "it"
  )

  const [logoHasBackground, setLogoHasBackground] = useState(false)

  StateContext.location = location.pathname
  StateContext.locale = currentLocale
  StateContext.isSpecialPage = isSpecialPage
  StateContext.setLogoHasBackground = setLogoHasBackground
  StateContext.logoHasBackground = logoHasBackground

  const { width } = useWindowSize()
  StateContext.width = width

  useEffect(() => {
    location.pathname.includes("/ambiti/") ||
    location.pathname.includes("/scopes/")
      ? setIsSpecialPage(true)
      : setIsSpecialPage(false)
  }, [location.pathname])

  const navbarRef = useRef(null)
  useEffect(() => {
    StateContext.navHeight = navbarRef.current.getBoundingClientRect().height
  }, [navbarRef, width])

  const [isScrolled, setIsScrolled] = useState(false)
  const [scrollDirection, setScrollDirection] = useState(null)
  const { scrollYProgress } = useScroll()

  useEffect(() => {
    return scrollYProgress.onChange(latest => {
      if (latest > 0) {
        scrollYProgress.prev > latest
          ? setScrollDirection("up")
          : setScrollDirection("down")
        setIsScrolled(true)
        StateContext.isScrolled = true
      } else {
        setIsScrolled(false)
        StateContext.isScrolled = false
        setScrollDirection(null)
      }
    })
  }, [scrollYProgress])

  return (
    <StateContext.Provider value={StateContext}>
      <>
        <AnimatePresence>
          {isSpecialPage && (
            <motion.div
              ititial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ y: "100%", opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="fixed top-0 left-0 w-[100vw] h-[100vh] z-[-9] bg-dkBlack"
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          <Header
            location={location}
            menuState={menuState}
            setMenuState={setMenuState}
            currentLocale={currentLocale}
            setCurrentLocale={setCurrentLocale}
            isScrolled={isScrolled}
            scrollDirection={scrollDirection}
            navbarRef={navbarRef}
          />
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter={false}>
          <AnimatedMain location={location}>
            {React.cloneElement(children, {
              menuState: menuState,
              location: location,
              currentLocale: currentLocale,
            })}
          </AnimatedMain>
        </AnimatePresence>
        <Footer />
      </>
    </StateContext.Provider>
  )
}

export default Layout
