import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { useWpMenu } from "../../hooks/wp/useWpMenu"
import { useWpMenuOrder } from "../../hooks/wp/useWpMenuOrder"

import { formatMenuQuery } from "../../functions/utils/formatMenuQuery"
import _ from "lodash"
import { AnimatePresence } from "framer-motion"
// import LanguageSwitcher from "./LanguageSwitcher"

import { StateContext } from "./StateContext"

import Arrow from "../../../assets/arrow.svg"

// import useWindowSize from "../../hooks/useWindowSize"
const animation = {
  initial: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
  close: {
    opacity: 0,
  },
}
const MotionMenu = ({
  location,
  currentLocale,
  setCurrentLocale,
  setSecondaryFunction,
  secondaryFunction,
}) => {
  const { wpMenu } = useWpMenu()
  const columns = formatMenuQuery(wpMenu, useWpMenuOrder())

  const [whichOpen, setWhichOpen] = useState(0)

  const [secondColContent, setSecondColContent] = useState(null)
  const [isSecondColOpen, setIsSecondColOpen] = useState(false)
  const [whichSecondColActive, setWhichSecondColActive] = useState(null)
  const handleFirstColumnClick = index => {
    if (whichSecondColActive !== index) {
      setWhichSecondColActive(index)
      setIsSecondColOpen(true)
      setWhichOpen(1)
    } else {
      if (isSecondColOpen) {
        setIsSecondColOpen(false)
        setIsThirdColOpen(false)
        setWhichOpen(0)
      } else {
        setIsSecondColOpen(true)
        setWhichOpen(1)
      }
    }
  }

  const [thirdColContent, setThirdColContent] = useState(null)
  const [isThirdColOpen, setIsThirdColOpen] = useState(false)
  const [whichThirdColActive, setWhichThirdColActive] = useState(null)
  const handleSecondColumnClick = index => {
    if (whichThirdColActive !== index) {
      setWhichThirdColActive(index)
      setIsThirdColOpen(true)
      setWhichOpen(2)
    } else {
      if (isThirdColOpen) {
        setIsThirdColOpen(false)
        setWhichOpen(1)
      } else {
        setIsThirdColOpen(true)
        setWhichOpen(2)
      }
    }
  }

  useEffect(() => {
    if (whichOpen > 0) {
      setSecondaryFunction(whichOpen)
    } else {
      setSecondaryFunction(null)
    }
  }, [whichOpen, setSecondaryFunction])

  const handleMobileBackClick = () => {
    if (whichOpen > 0) {
      if (whichOpen === 1) {
        setIsSecondColOpen(false)
      } else {
        setIsThirdColOpen(false)
      }
      setSecondaryFunction(null)
      setWhichOpen(0)
    }
  }

  return (
    <motion.div
      style={{
        paddingTop:
          StateContext.width > 1015
            ? 300
            : StateContext.width < 500
            ? 150
            : 185,
      }}
      className="bg-black w-full fixed top-0 left-0 h-full text-white  pb-10 md:pb-20 px-6 sm:px-16 md:px-36 z-[2] flex flex-col justify-between text-xl "
      variants={animation}
      initial="initial"
      animate="open"
      exit="close"
    >
      <AnimatePresence>
        {StateContext.width < 850 &&
          whichOpen > 0 &&
          secondaryFunction !== null && (
            <motion.div
              initial={{ opacity: 0, x: "100%" }}
              animate={{ opacity: 1, x: "0%" }}
              exit={{ opacity: 0, x: "100%" }}
              className="absolute top-6 right-6 z-[999]"
            >
              <button onClick={() => handleMobileBackClick()}>
                <Arrow style={{ transform: "rotate(180deg)" }} />
              </button>
            </motion.div>
          )}
      </AnimatePresence>
      <div className="grid md:grid-cols-3 gap-x-4 h-full pb-10 pt-8 relative">
        <MenuColumn
          position={1}
          currentLocale={currentLocale}
          content={columns.colA}
          handleClick={handleFirstColumnClick}
          setSecondColContent={setSecondColContent}
          isSecondColOpen={isSecondColOpen}
          isThirdColOpen={isThirdColOpen}
          whichSecondColActive={whichSecondColActive}
          whichThirdColActive={whichThirdColActive}
        />
        <AnimatePresence>
          {isSecondColOpen && (
            <MenuColumn
              position={2}
              currentLocale={currentLocale}
              content={secondColContent}
              handleClick={handleSecondColumnClick}
              setThirdColContent={setThirdColContent}
              isSecondColOpen={isSecondColOpen}
              isThirdColOpen={isThirdColOpen}
              whichSecondColActive={whichSecondColActive}
              whichThirdColActive={whichThirdColActive}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isThirdColOpen && (
            <MenuColumn
              position={3}
              currentLocale={currentLocale}
              content={thirdColContent}
              isSecondColOpen={isSecondColOpen}
              isThirdColOpen={isThirdColOpen}
              whichSecondColActive={whichSecondColActive}
              whichThirdColActive={whichThirdColActive}
            />
          )}
        </AnimatePresence>
      </div>
      {/* <LanguageSwitcher
        location={location}
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      /> */}
    </motion.div>
  )
}

export default MotionMenu

const MenuColumn = ({
  currentLocale,
  position,
  content,
  handleClick,
  setSecondColContent,
  setThirdColContent,
  isSecondColOpen,
  isThirdColOpen,
  whichSecondColActive,
  whichThirdColActive,
}) => {
  let pos = ""
  switch (position) {
    case 1:
      pos = "col-start-1"
      break
    case 2:
      pos = "col-start-2"
      break
    case 3:
      pos = "col-start-3"
      break
    default:
      pos = "col-start-2"
      break
  }

  const handleToggle = (ind, children) => {
    handleClick(ind)
    if (setSecondColContent !== undefined) {
      setSecondColContent(children)
    } else if (setThirdColContent !== undefined) {
      let key = _.findKey(content, function (o) {
        return o.hasChildren === true
      })
      setThirdColContent(content[key].children)
    }
  }

  const translate = node => {
    let title = ""
    if (currentLocale === "it") {
      title = node.title
    } else if (currentLocale === "en") {
      title =
        node.translated[0]?.title === undefined ||
        node.translated[0]?.title === ""
          ? node.title
          : node.translated[0].title
    }

    return title
  }

  const translateUri = node => {
    let url = ""
    if (currentLocale === "it") {
      url = node.uri
    } else if (currentLocale === "en") {
      url =
        node.translated[0]?.localizedWpmlUrl === undefined ||
        node.translated[0]?.localizedWpmlUrl === ""
          ? node.uri
          : node.translated[0]?.localizedWpmlUrl
    }
    return url
  }

  const column = {
    initial: {
      opacity: 0,
      x: "-100%",
      zIndex: 1,
    },
    entrance: {
      opacity: 1,
      x: "0%",
      zIndex: 2,
    },
    exit: {
      opacity: 0,
      x: "-100%",
      zIndex: 1,
    },
  }

  const toggleAnimations = {
    initial: {
      opacity: 1,
    },
    open: {
      opacity: 0,
    },
    close: {
      opacity: 1,
    },
  }

  return (
    <motion.div
      className={`flex flex-col uppercase ${pos} ${
        StateContext.width < 868
          ? "absolute w-[100%] z-[99] bg-black h-[100%] top-0"
          : ""
      }`}
      variants={column}
      initial="initial"
      animate="entrance"
      exit="exit"
      transition={{ type: "tween" }}
    >
      {content?.map((node, index) => {
        if (node.hasChildren && node.children.length > 0) {
          return (
            <button
              key={`col-${pos}-${index}`}
              className={`w-[100%] text-left flex flex-row justify-between items-center uppercase pb-8 ${
                setSecondColContent !== undefined
                  ? isSecondColOpen && whichSecondColActive === index
                    ? "text-white"
                    : "text-mdGray"
                  : setThirdColContent !== undefined &&
                    isThirdColOpen &&
                    whichThirdColActive === index
                  ? "text-white"
                  : "text-mdGray"
              } `}
              onClick={() => handleToggle(index, node.children)}
            >
              {translate(node.node)}
              <div>
                <div
                  className={`w-[20px] h-[2px] ${
                    setSecondColContent !== undefined
                      ? isSecondColOpen && whichSecondColActive === index
                        ? "bg-white"
                        : "bg-mdGray"
                      : setThirdColContent !== undefined &&
                        isThirdColOpen &&
                        whichThirdColActive === index
                      ? "bg-white"
                      : "bg-mdGray"
                  }`}
                />
                <motion.div
                  className={`w-[20px] h-[2px] bg-mdGray rotate-90 translate-y-[-1px]`}
                  variants={toggleAnimations}
                  initial="initial"
                  animate={
                    setSecondColContent !== undefined
                      ? isSecondColOpen && whichSecondColActive === index
                        ? "open"
                        : "close"
                      : setThirdColContent !== undefined &&
                        isThirdColOpen &&
                        whichThirdColActive === index
                      ? "open"
                      : "close"
                  }
                />
              </div>
            </button>
          )
        } else {
          return (
            <Link
              key={`col-${pos}-${index}`}
              to={translateUri(node.node)}
              className="pb-8 text-mdGray"
            >
              {translate(node.node)}
            </Link>
          )
        }
      })}
    </motion.div>
  )
}
