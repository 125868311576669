import { useStaticQuery, graphql } from "gatsby"

export const useWpMenu = () => {
  const data = useStaticQuery(graphql`
    query wpMenuQuery {
      wpMenu(slug: { eq: "reviewed" }) {
        menuItems {
          nodes {
            uri
            connectedNode {
              node {
                id
                ... on WpPage {
                  id
                  uri
                  title
                  slug
                  translated {
                    id
                    title
                    localizedWpmlUrl
                  }
                  wpParent {
                    node {
                      id
                      slug
                      ... on WpPage {
                        id
                        uri
                        title
                        translated {
                          id
                          title
                          localizedWpmlUrl
                        }
                      }
                    }
                  }
                  wpChildren {
                    nodes {
                      id
                      slug
                      ... on WpPage {
                        id
                        uri
                        title
                        translated {
                          id
                          title
                          localizedWpmlUrl
                        }
                        wpChildren {
                          nodes {
                            id
                            slug
                            ... on WpPage {
                              id
                              uri
                              title
                              translated {
                                id
                                title
                                localizedWpmlUrl
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data
}
