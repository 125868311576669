import React, { useState, useEffect, useCallback } from "react"
import Logo from "./Logo"
import LogoMini from "./LogoMini"

import { motion, AnimatePresence } from "framer-motion"
import MotionMenu from "./MotionMenu"
import Hamburger from "./Hamburger"

import { StateContext } from "./StateContext"
import SectionWrapper from "../DesignSystem/components/SectionWrapper"
import SearchButton from "./SearchButton"
import CrossWhite from "../../../assets/cross-white.svg"
import { navigate } from "gatsby"

const navbarAnimations = {
  initial: {
    y: "0%",
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "tween",
    },
  },
  scrolled: {
    y: "-100%",
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
}

const miniNavbarAnimations = {
  initial: {
    y: "-100%",
    opacity: 0,
    transition: {
      duration: 0.3,
      type: "tween",
    },
  },
  show: {
    y: "0%",
    opacity: 1,
    transition: {
      duration: 0.5,
      type: "tween",
    },
  },
}

const Header = ({
  location,
  menuState,
  setMenuState,
  currentLocale,
  setCurrentLocale,
  isScrolled,
  scrollDirection,
  navbarRef,
}) => {
  const locationSwitchKey =
    StateContext.locale === "it"
      ? location.pathname.split("/")[1]
      : location.pathname.split("/")[2]

  const [secondaryFunction, setSecondaryFunction] = useState(null)

  const [searchState, setSearchState] = useState(false)
  const [query, setQuery] = useState("")

  useEffect(() => {
    setMenuState(false)
    setSearchState(false)
    setSecondaryFunction(null)
  }, [location.pathname, setMenuState, setSearchState])

  const keyNavigation = useCallback(
    event => {
      if (event.keyCode === 27) {
        setMenuState(false)
        setSearchState(false)
      }
    },
    [setMenuState, setSearchState]
  )
  useEffect(() => {
    document.addEventListener("keydown", keyNavigation, false)
    return () => {
      document.removeEventListener("keydown", keyNavigation, false)
    }
  })

  const handleSubmit = event => {
    let dest = ""
    if (StateContext.locale === "it") {
      dest = "/search/"
    } else {
      dest = "/en/search/"
    }
    dest = dest + "?search=" + query
    event.preventDefault()
    navigate(dest)
  }
  return (
    <>
      <motion.header
        ref={navbarRef}
        className={`fixed top-0 left-0 w-full ${
          StateContext.width < 850 && secondaryFunction !== null
            ? "z-[2]"
            : "z-[3]"
        }`}
        variants={navbarAnimations}
        animate={!menuState && isScrolled ? "scrolled" : "initial"}
      >
        {!searchState && (
          <Nav
            isMini={false}
            locationSwitchKey={locationSwitchKey}
            menuState={menuState}
            setMenuState={setMenuState}
            secondaryFunction={secondaryFunction}
            searchState={searchState}
            setSearchState={setSearchState}
          />
        )}
      </motion.header>

      <motion.header
        className="bg-white fixed top-0 left-0 w-full z-[2] pb-8 shadow-md"
        variants={miniNavbarAnimations}
        initial="initial"
        animate={scrollDirection === "up" ? "show" : "initial"}
      >
        <Nav
          isMini={true}
          locationSwitchKey={locationSwitchKey}
          menuState={menuState}
          setMenuState={setMenuState}
          secondaryFunction={secondaryFunction}
          searchState={searchState}
          setSearchState={setSearchState}
        />
      </motion.header>

      <AnimatePresence>
        {menuState && (
          <MotionMenu
            location={location}
            currentLocale={currentLocale}
            setCurrentLocale={setCurrentLocale}
            setSecondaryFunction={setSecondaryFunction}
            secondaryFunction={secondaryFunction}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {searchState && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: "-100%", opacity: 0 }}
            className="bg-black w-full fixed top-0 left-0 h-full text-white px-6 sm:px-16 md:px-36 z-[2] flex flex-col justify-center items-center text-xl "
          >
            <button
              className="absolute right-6 sm:right-16 md:right-36 top-10  "
              onClick={() => setSearchState(false)}
            >
              <CrossWhite />
            </button>
            <form
              className="text-white font-light"
              onSubmit={ev => handleSubmit(ev)}
            >
              <label>
                <input
                  type="text"
                  name="search"
                  placeholder={
                    StateContext.locale === "it"
                      ? "Cerca per parola chiave"
                      : "Search by keywords"
                  }
                  value={query}
                  onChange={ev => setQuery(ev.target.value)}
                  className="p-4 px-20 bg-transparent border-b-2 border-dkGray focus:outline-none "
                />
              </label>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Header

const Nav = ({
  isMini,
  locationSwitchKey,
  menuState,
  setMenuState,
  secondaryFunction,
  searchState,
  setSearchState,
}) => {
  return (
    <SectionWrapper>
      <nav className="flex gap-x-2">
        {!isMini ? (
          <Logo location={locationSwitchKey} menuState={menuState} />
        ) : (
          <LogoMini
            location={locationSwitchKey}
            menuState={menuState}
            isFooter={false}
          />
        )}
        <div className="flex justify-end w-full">
          <div className="flex  items-center h-[50px] gap-x-8">
            {secondaryFunction === null && (
              <>
                <SearchButton
                  menuState={menuState}
                  searchState={searchState}
                  setSearchState={setSearchState}
                />
                <Hamburger
                  menuState={menuState}
                  setMenuState={setMenuState}
                  isMini={isMini}
                  secondaryFunction={secondaryFunction}
                />
              </>
            )}
          </div>
        </div>
      </nav>
    </SectionWrapper>
  )
}
