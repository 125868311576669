import React from "react"
import SearchBlack from "../../../assets/search.svg"
import SearchWhite from "../../../assets/search-white.svg"
import { StateContext } from "./StateContext"

const SearchButton = ({ menuState, searchState, setSearchState }) => {
  return (
    <button
      className="relative z-[3]"
      onClick={() => {
        setSearchState(!searchState)
      }}
    >
      {menuState ||
      StateContext.logoHasBackground ||
      StateContext.isSpecialPage ? (
        <SearchWhite />
      ) : (
        <SearchBlack />
      )}
    </button>
  )
}

export default SearchButton
