import React from "react"
import { StateContext } from "../../PageLayout/StateContext"
import PropTypes from "prop-types"

const SectionWrapper = ({ children, ultra, isFirst, fullBleed, removeY }) => {
  return (
    <div
      className={`relative ${
        ultra
          ? "px-8 sm:px-20 md:px-60"
          : fullBleed && StateContext.width < 800
          ? "px-0"
          : fullBleed
          ? "px-0"
          : "px-6 sm:px-16 md:px-36"
      } 
        
       ${
         !isFirst && !removeY
           ? "pt-10"
           : StateContext.location !== "/" && !removeY
           ? "pt-8"
           : ""
       } ${!removeY ? "pt-10" : ""}`}
    >
      {children}
    </div>
  )
}

export default SectionWrapper

SectionWrapper.propTypes = {
  children: PropTypes.node,
  isFirst: PropTypes.bool,
  ultra: PropTypes.bool,
  fullBleed: PropTypes.bool,
  removeY: PropTypes.bool,
}

SectionWrapper.defaultProps = {
  children: <></>,
  isFirst: false,
  ultra: false,
  fullBleed: false,
  removeY: false,
}
