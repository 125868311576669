import React from "react"
import LocalizeLink from "./LocalizedLink"
import { StateContext } from "./StateContext"
import { motion } from "framer-motion"

const colors = {
  initial: {
    fill: "#848484",
  },
  menuOpen: {
    fill: "#ffffff",
  },
  "la-fondazione": {
    fill: "#BD63F5",
  },
  "the-fundation": {
    fill: "#BD63F5",
  },
  ambiti: {
    fill: "#EA651A",
  },
  scopes: {
    fill: "#EA651A",
  },
  pubblicazioni: {
    fill: "#0FC9C9",
  },
  publications: {
    fill: "#0FC9C9",
  },
  "mostre-e-altri-eventi": {
    fill: "#4FE179",
  },
  "exhibitions-and-events": {
    fill: "#4FE179",
  },
}

const LogoMini = ({ location, menuState, isFooter }) => {
  return (
    <LocalizeLink to="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={
          StateContext.width > 800
            ? `342.754`
            : StateContext.width < 530
            ? `200`
            : `320`
        }
        height={
          StateContext.width > 800
            ? `60`
            : StateContext.width < 530
            ? `50`
            : `60`
        }
        viewBox="0 0 292.754 45.555"
      >
        <g
          id="Group_5480"
          data-name="Group 5480"
          transform="translate(-240 -55)"
        >
          <g
            id="Group_5426"
            data-name="Group 5426"
            transform="translate(54.85 -482.141)"
          >
            <motion.path
              id="Path_5153"
              variants={colors}
              initial="initial"
              animate={menuState ? "menuOpen" : location}
              data-name="Path 5153"
              d="M236.3,581.282c-7.513-3.009-8.645-14.436-8.645-21.453,0-7.126,1.132-18.465,8.645-21.454v-1.233c-8.83,2.226-15.836,11.451-15.836,22.688,0,13.024,6.616,21.03,15.836,22.825Z"
              transform="translate(-16.255 0)"
              fill="#575756"
            />
            <motion.path
              id="Path_5154"
              variants={colors}
              initial="initial"
              animate={menuState ? "menuOpen" : location}
              data-name="Path 5154"
              d="M265.116,538.336a14.843,14.843,0,0,1,10.151,12.96h1.009V537.8h-1.009c0,1.577-.316,3.156-2.208,3.156-1.608,0-3.824-2.551-7.942-3.715Z"
              transform="translate(-36.808 -0.045)"
              fill="#575756"
            />
            <motion.path
              id="Path_5155"
              variants={colors}
              initial="initial"
              animate={menuState ? "menuOpen" : location}
              data-name="Path 5155"
              d="M265.116,584.908v1.458H271.1l0,9.958c0,4.3-2.3,6.48-5.987,7.3v1.061a25.938,25.938,0,0,0,12.8-6.4V586.366H282.9v-1.458Z"
              transform="translate(-36.808 -21.987)"
              fill="#575756"
            />
            <motion.path
              id="Path_5156"
              variants={colors}
              initial="initial"
              animate={menuState ? "menuOpen" : location}
              data-name="Path 5156"
              d="M314.256,543.641a13.148,13.148,0,0,1,8.992,11.484h.894V543.166h-.894c0,1.4-.275,2.795-1.956,2.795-1.425,0-3.387-2.26-7.036-3.291Z"
              transform="translate(-59.428 -2.545)"
              fill="#575756"
            />
            <motion.path
              id="Path_5157"
              variants={colors}
              initial="initial"
              animate={menuState ? "menuOpen" : location}
              data-name="Path 5157"
              d="M314.256,584.908v1.29h5.307l0,8.823c0,3.806-2.034,5.732-5.3,6.467v.934a22.948,22.948,0,0,0,11.342-5.667V586.2h4.413v-1.29Z"
              transform="translate(-59.428 -21.987)"
              fill="#575756"
            />
            <motion.path
              id="Path_5158"
              variants={colors}
              initial="initial"
              animate={menuState ? "menuOpen" : location}
              data-name="Path 5158"
              d="M185.151,544.952h9.374v2.224h-6.847v5.155h6.545v2.225h-6.545v8.036h-2.528Z"
              transform="translate(0 -3.596)"
              fill="#575756"
            />
          </g>
          {!isFooter && (
            <g
              id="Group_5431"
              data-name="Group 5431"
              transform="translate(-3.889 -527.328)"
            >
              <path
                id="Path_5159"
                data-name="Path 5159"
                d="M361.771,600.594V587.328h7.5v1.1h-6.3v4.686h6.082v1.1h-6.082v6.376Z"
                transform="translate(0.165)"
                fill="#575756"
              />
              <path
                id="Path_5160"
                data-name="Path 5160"
                d="M377.572,595.391c0,2.425-1.36,4.888-4.3,4.888s-4.3-2.462-4.3-4.888,1.36-4.889,4.3-4.889S377.572,592.965,377.572,595.391Zm-4.3-3.878c-2.224,0-3.105,2.169-3.105,3.878s.882,3.877,3.105,3.877,3.105-2.168,3.105-3.877S375.5,591.513,373.273,591.513Z"
                transform="translate(1.508 0.592)"
                fill="#575756"
              />
              <path
                id="Path_5161"
                data-name="Path 5161"
                d="M379.259,600h-1.1v-7.147c0-.717-.055-1.452-.091-2.077H379.2l.056,1.36.037.037a3.169,3.169,0,0,1,2.885-1.673c3.013,0,3.252,2.665,3.252,3.731V600h-1.1v-5.953a2.282,2.282,0,0,0-2.427-2.536c-1.819,0-2.645,1.526-2.645,3.179Z"
                transform="translate(3.205 0.592)"
                fill="#575756"
              />
              <path
                id="Path_5162"
                data-name="Path 5162"
                d="M393.208,599.234l-.037-.073a3.744,3.744,0,0,1-3.16,1.71c-2.739,0-3.785-2.609-3.785-4.924,0-2.389,1.047-4.852,3.785-4.852a3.687,3.687,0,0,1,3.16,1.673l.037-.037v-5.4h1.1v11.191c0,.716.056,1.451.093,2.075h-1.139Zm-3.013-7.129c-2.1,0-2.775,1.948-2.775,3.712,0,1.709.5,4.043,2.609,4.043,2.408,0,3.142-2,3.142-4.1C393.171,593.888,392.344,592.105,390.2,592.105Z"
                transform="translate(4.728)"
                fill="#575756"
              />
              <path
                id="Path_5163"
                data-name="Path 5163"
                d="M400.709,593.5c-.11-1.507-.883-1.985-2.187-1.985-1.158,0-2.113.349-2.26,1.6h-1.176c.276-1.894,1.691-2.611,3.454-2.611,2.04,0,3.308.937,3.271,3.051v4.374c-.019.716.055,1.451.091,2.075h-1.139l-.037-1.36h-.037l-.055.129a3.463,3.463,0,0,1-2.885,1.507,2.85,2.85,0,0,1-1.213-5.513c1.23-.533,2.829-.349,4.171-.422Zm-2.756,5.769c2.406,0,2.866-1.984,2.756-3.913-1.563.055-4.833-.24-4.833,2.075A1.842,1.842,0,0,0,397.952,599.267Z"
                transform="translate(6.306 0.592)"
                fill="#575756"
              />
              <path
                id="Path_5164"
                data-name="Path 5164"
                d="M402.487,598.949l5.384-7.2h-5.384v-1.01h6.689v1.01l-5.348,7.2h5.348v1.01h-6.689Z"
                transform="translate(7.762 0.636)"
                fill="#575756"
              />
              <path
                id="Path_5165"
                data-name="Path 5165"
                d="M411.206,588.706H409.92v-1.378h1.286Zm-.091,2.665v9.223h-1.1v-9.223Z"
                transform="translate(9.149)"
                fill="#575756"
              />
              <path
                id="Path_5166"
                data-name="Path 5166"
                d="M421.462,595.391c0,2.425-1.36,4.888-4.3,4.888s-4.3-2.462-4.3-4.888,1.36-4.889,4.3-4.889S421.462,592.965,421.462,595.391Zm-4.3-3.878c-2.222,0-3.1,2.169-3.1,3.878s.882,3.877,3.1,3.877,3.105-2.168,3.105-3.877S419.386,591.513,417.162,591.513Z"
                transform="translate(9.698 0.592)"
                fill="#575756"
              />
              <path
                id="Path_5167"
                data-name="Path 5167"
                d="M423.148,600h-1.1v-7.147c0-.717-.056-1.452-.093-2.077h1.139l.056,1.36.037.037a3.171,3.171,0,0,1,2.885-1.673c3.014,0,3.254,2.665,3.254,3.731V600h-1.1v-5.953a2.281,2.281,0,0,0-2.425-2.536c-1.819,0-2.646,1.526-2.646,3.179Z"
                transform="translate(11.394 0.592)"
                fill="#575756"
              />
              <path
                id="Path_5168"
                data-name="Path 5168"
                d="M431.372,595.647c.037,1.966.936,3.62,3.2,3.62a2.748,2.748,0,0,0,2.664-2.2h1.158a3.872,3.872,0,0,1-4.171,3.216c-2.757,0-4.043-2.371-4.043-4.888,0-2.5,1.379-4.889,4.116-4.889,3.086,0,4.208,2.26,4.208,5.145Zm5.934-1.01c-.128-1.782-1.083-3.124-2.995-3.124-1.8,0-2.755,1.507-2.9,3.124Z"
                transform="translate(12.929 0.592)"
                fill="#575756"
              />
              <path
                id="Path_5169"
                data-name="Path 5169"
                d="M370.37,610.034a2.5,2.5,0,0,0-.368-1.342,2.543,2.543,0,0,0-2.222-1.029c-3.233,0-3.877,3.014-3.877,5.052a5.363,5.363,0,0,0,1.1,3.566,3.79,3.79,0,0,0,3.123,1.139,6.446,6.446,0,0,0,2.168-.257V614.37h-2.573v-2.2h5.422V618.8a13.705,13.705,0,0,1-4.852.734c-3.032,0-4.593-.734-5.641-1.763a6.9,6.9,0,0,1-1.763-4.961c0-4.7,2.94-7.222,6.964-7.222a5.986,5.986,0,0,1,4.52,1.672,4.044,4.044,0,0,1,.956,2.775Z"
                transform="translate(0 3.407)"
                fill="#575756"
              />
              <path
                id="Path_5170"
                data-name="Path 5170"
                d="M376.034,609.273V614.8c0,.808,0,2.04,1.451,2.04,1.747,0,1.747-1.305,1.747-2.885v-4.686h2.792v6.118c.037,2,.056,2.243.056,2.481.018.258.037.422.074.68h-2.646l-.093-1.232a3.04,3.04,0,0,1-2.737,1.47,3.535,3.535,0,0,1-2.793-1.249,4.275,4.275,0,0,1-.606-2.5v-5.769Z"
                transform="translate(2.312 4.095)"
                fill="#575756"
              />
              <path
                id="Path_5171"
                data-name="Path 5171"
                d="M391.684,609.319a20.707,20.707,0,0,0-.129,2.3v6.854c0,3.417-2.848,3.748-4.63,3.748-1.323,0-3.913-.165-4.08-2.976h2.609c.037.275.166,1.286,1.617,1.286a1.589,1.589,0,0,0,1.691-1.213,9.9,9.9,0,0,0,.128-2.206,2.916,2.916,0,0,1-2.5,1.268c-2.664,0-3.913-2.315-3.913-4.556,0-2.353,1.3-4.595,3.969-4.595a2.811,2.811,0,0,1,2.59,1.323l.091-1.231Zm-4.667,7.2c1.91,0,1.91-2.242,1.91-2.646,0-1.048-.2-2.83-1.856-2.83-1.029,0-1.875.79-1.875,2.776C385.2,614.28,385.2,616.521,387.017,616.521Z"
                transform="translate(4.028 4.086)"
                fill="#575756"
              />
              <path
                id="Path_5172"
                data-name="Path 5172"
                d="M392.281,605.9H395v13.285h-2.718Z"
                transform="translate(5.858 3.465)"
                fill="#575756"
              />
              <path
                id="Path_5173"
                data-name="Path 5173"
                d="M396.509,605.913h2.9v2.37h-2.9Zm.091,3.968h2.72v9.3H396.6Z"
                transform="translate(6.647 3.468)"
                fill="#575756"
              />
              <path
                id="Path_5174"
                data-name="Path 5174"
                d="M403.144,614.651c0,.606,0,2.48,1.893,2.48a1.52,1.52,0,0,0,1.617-1.286h2.48a3.1,3.1,0,0,1-.882,2.021A4.511,4.511,0,0,1,405,618.95a4.614,4.614,0,0,1-3.27-1.158,6.012,6.012,0,0,1,.735-8.011,4.648,4.648,0,0,1,2.609-.772,3.984,3.984,0,0,1,3.748,2.243,6.88,6.88,0,0,1,.552,3.4Zm3.454-1.71c0-.422-.037-2.076-1.616-2.076-1.195,0-1.691.992-1.747,2.076Z"
                transform="translate(7.398 4.046)"
                fill="#575756"
              />
              <path
                id="Path_5175"
                data-name="Path 5175"
                d="M409.5,605.9h2.72v13.285H409.5Z"
                transform="translate(9.071 3.465)"
                fill="#575756"
              />
              <path
                id="Path_5176"
                data-name="Path 5176"
                d="M413.868,611.05c0-.589-.037-1.158-.091-1.745H416.4l.091,1.194a3.2,3.2,0,0,1,2.867-1.4,2.83,2.83,0,0,1,2.921,1.746,3.083,3.083,0,0,1,2.977-1.727,3.042,3.042,0,0,1,2.83,1.488,5.339,5.339,0,0,1,.459,2.737v5.238h-2.737v-5.457c0-.993-.147-2.1-1.563-2.1-1.635,0-1.672,1.525-1.672,2.9v4.649h-2.739v-5.513c0-.717-.018-2.04-1.525-2.04-1.709,0-1.709,1.47-1.709,2.1v5.457h-2.737Z"
                transform="translate(9.869 4.063)"
                fill="#575756"
              />
              <path
                id="Path_5177"
                data-name="Path 5177"
                d="M432.867,609.087c3.362,0,4.814,2.278,4.814,4.87,0,2.608-1.507,4.96-4.87,4.96-2.646,0-4.74-1.635-4.74-4.888C428.071,611.788,429.21,609.087,432.867,609.087Zm.037,7.938c1.194,0,2-.717,2-3.179,0-1.232-.24-2.94-2.04-2.94-1.985,0-2,2.3-2,3.142C430.864,616.125,431.562,617.025,432.9,617.025Z"
                transform="translate(12.536 4.06)"
                fill="#575756"
              />
              <path
                id="Path_5178"
                data-name="Path 5178"
                d="M451.257,610.034a2.512,2.512,0,0,0-.367-1.342,2.545,2.545,0,0,0-2.224-1.029c-3.233,0-3.877,3.014-3.877,5.052a5.363,5.363,0,0,0,1.1,3.566,3.792,3.792,0,0,0,3.123,1.139,6.439,6.439,0,0,0,2.168-.257V614.37h-2.571v-2.2h5.42V618.8a13.7,13.7,0,0,1-4.851.734c-3.032,0-4.593-.734-5.641-1.763a6.9,6.9,0,0,1-1.764-4.961c0-4.7,2.94-7.222,6.964-7.222a5.989,5.989,0,0,1,4.521,1.672,4.048,4.048,0,0,1,.955,2.775Z"
                transform="translate(15.093 3.407)"
                fill="#575756"
              />
              <path
                id="Path_5179"
                data-name="Path 5179"
                d="M454.166,605.913h2.9v2.37h-2.9Zm.091,3.968h2.72v9.3h-2.72Z"
                transform="translate(17.405 3.468)"
                fill="#575756"
              />
              <path
                id="Path_5180"
                data-name="Path 5180"
                d="M462.988,609.087c3.363,0,4.815,2.278,4.815,4.87,0,2.608-1.507,4.96-4.87,4.96a4.486,4.486,0,0,1-4.742-4.888C458.192,611.788,459.331,609.087,462.988,609.087Zm.037,7.938c1.195,0,2-.717,2-3.179,0-1.232-.238-2.94-2.04-2.94-1.985,0-2,2.3-2,3.142C460.985,616.125,461.683,617.025,463.025,617.025Z"
                transform="translate(18.156 4.06)"
                fill="#575756"
              />
              <path
                id="Path_5181"
                data-name="Path 5181"
                d="M468.031,611.561c0-.441-.072-1.782-.128-2.315h2.554l.056,1.782c.349-.79.955-1.912,3.161-1.8v2.444c-2.756-.258-2.923,1.157-2.923,2.315v4.576h-2.72Z"
                transform="translate(19.969 4.085)"
                fill="#575756"
              />
              <path
                id="Path_5182"
                data-name="Path 5182"
                d="M479.953,605.913h2.737v11.759c0,.552.037,1.158.074,1.507h-2.755l-.074-1.342a2.5,2.5,0,0,1-.937,1.048,3.246,3.246,0,0,1-1.8.478c-2.481,0-3.951-1.709-3.951-4.612,0-3.068,1.672-4.98,4.1-4.98a2.972,2.972,0,0,1,2.609,1.268ZM477.877,617.6a1.964,1.964,0,0,0,1.727-1.048,4.932,4.932,0,0,0,.441-2.186c0-1.82-.79-2.812-2.022-2.812-1.819,0-2.021,2.187-2.021,3.124C476,616.221,476.517,617.6,477.877,617.6Z"
                transform="translate(20.966 3.468)"
                fill="#575756"
              />
              <path
                id="Path_5183"
                data-name="Path 5183"
                d="M488.822,618.6a7.474,7.474,0,0,1-.091-1.268,3.585,3.585,0,0,1-5.089.753,2.871,2.871,0,0,1-.809-2.04,3.087,3.087,0,0,1,1.966-2.885,9.679,9.679,0,0,1,3.675-.515,3.624,3.624,0,0,0-.128-1.231,1.258,1.258,0,0,0-1.213-.771,1.237,1.237,0,0,0-1.029.514,1.393,1.393,0,0,0-.2.827h-2.664a2.6,2.6,0,0,1,1.048-2.187,4.849,4.849,0,0,1,2.811-.753,4.462,4.462,0,0,1,3.307,1.195,3.67,3.67,0,0,1,.643,2.278l.018,3.731a11.25,11.25,0,0,0,.221,2.352Zm-3.344-2.867a1.279,1.279,0,0,0,1.323,1.323,1.536,1.536,0,0,0,1.157-.533,3.4,3.4,0,0,0,.515-2.3C486.728,614.075,485.478,614.626,485.478,615.729Z"
                transform="translate(22.754 4.051)"
                fill="#575756"
              />
              <path
                id="Path_5184"
                data-name="Path 5184"
                d="M491.839,610.966c0-.037,0-1.047-.055-1.69h2.609l.074,1.4a3.212,3.212,0,0,1,3.013-1.616,2.823,2.823,0,0,1,2.83,1.745,7.69,7.69,0,0,1,.258,2.774v5.017h-2.775v-5.77a2.224,2.224,0,0,0-.146-1.01,1.417,1.417,0,0,0-1.323-.79,1.691,1.691,0,0,0-1.416.717,2.54,2.54,0,0,0-.33,1.414v5.439h-2.739Z"
                transform="translate(24.425 4.054)"
                fill="#575756"
              />
              <path
                id="Path_5185"
                data-name="Path 5185"
                d="M505.7,609.087c3.363,0,4.815,2.278,4.815,4.87,0,2.608-1.507,4.96-4.871,4.96a4.485,4.485,0,0,1-4.74-4.888C500.9,611.788,502.044,609.087,505.7,609.087Zm.037,7.938c1.194,0,2-.717,2-3.179,0-1.232-.239-2.94-2.04-2.94-1.985,0-2,2.3-2,3.142C503.7,616.125,504.4,617.025,505.738,617.025Z"
                transform="translate(26.127 4.06)"
                fill="#575756"
              />
            </g>
          )}
        </g>
      </svg>
    </LocalizeLink>
  )
}

export default LogoMini
