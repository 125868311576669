import React, { useState, useEffect } from "react"
import LogoMini from "./LogoMini"
import { useContatti } from "../../hooks/wp/useContatti"
import { StateContext } from "./StateContext"
import { Link } from "gatsby"
import { useFooterSitemap } from "../../hooks/wp/useFooterSitemap"

const Footer = () => {
  const { wpPage } = useContatti()
  const [info, setInfo] = useState(null)
  const footerSitemap = useFooterSitemap()

  useEffect(() => {
    if (StateContext.locale === "it") {
      setInfo(wpPage.contattiFondazione)
    } else if (StateContext.locale === "en" && wpPage.translated.length > 0) {
      setInfo(wpPage.translated[0].contattiFondazione)
    }
  }, [wpPage])

  return (
    <div
      className={`${
        StateContext.isSpecialPage ? "bg-transparent text-white" : "bg-white"
      } pt-20 pb-20 md:pb-0`}
    >
      <div
        className={`flex flex-col md:flex-row justify-between items-start border-t-2 ${
          StateContext.isSpecialPage ? "border-white" : "border-black"
        } mx-4 sm:mx-6 md:mx-20 py-8`}
      >
        <div className="flex flex-col md:flex-row gap-x-2 gap-y-6">
          <LogoMini isFooter={true} />
          {info?.informazioni !== null && (
            <div
              className="pb-4"
              dangerouslySetInnerHTML={{
                __html: `${info?.informazioni}`,
              }}
            />
          )}
        </div>
        <div className="flex flex-col">
          {footerSitemap.allWpPage.edges.map((edge, index) => {
            edge =
              StateContext.locale === "it"
                ? edge.node
                : StateContext.locale === "en" &&
                  edge.node?.translated.length > 0 &&
                  edge.node?.translated[0]

            return (
              <Link key={`footer-link-${index}`} to={edge.uri}>
                {edge.title}
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Footer
